import React, { useEffect, useState } from 'react'
import { EnsurePagePermission } from '../../components/auth'
import Layout from '../../components/layout/layout'
import Footer from '../../components/layout/footer'
import { firestore } from 'gatsby-theme-firebase'
import { Container } from 'react-bootstrap'
import { Badge, Spinner, Toast } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug, faCopy, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import ReportPlaceModal from'../../components/modals/reportPlace'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css'
import { CopyToClipboard } from 'react-copy-to-clipboard'
export default function List() {

  const [profile, setProfile] = useState()
  const [isAllowed, setIsAllowed] = useState(false)
  EnsurePagePermission('list', setIsAllowed, setProfile)

  const placesRef = firestore.collection('pin_places')

  const [toastCopied, setToastCopied] = useState(false)
  const [toastCopiedTitle, setToastCopiedTitle] = useState('')

  // load 
  const [places, setPlaces] = useState([])
  const [pending, setPending] = useState(true)

  // modal "report place"
  // const handleShowReport = () => setReport(true)
  const handleCloseReport = () => setReport(false)
  const [report, setReport] = useState(false)

  // storing PID of currently selected place
  const [placeID, setPlaceID] = useState('')

  const LinearIndeterminate = () => {
    return (
      <div style={{justifyContent: 'center', alignItems: 'center'}}>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    )
  }

  const customStyles = {
    headRow: {
      style: {
        border: 'none',
      },
    },
    subHeader: {
      style: {
        maxHeight: '52px',
        marginTop: '15px',
        marginBottom: '15px',
      },
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '14px',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: '#3771c82b',
        borderBottomColor: '#FFFFFF',
        borderRadius: '10px',
        outline: '1px solid #FFFFFF',
      },
    },
  }

  const columns = [
    {
      name: 'Place ID',
      selector: 'pid',
      sortable: false,
      wrap: true,
      minWidth: '140px',
      style: {
        color: 'rgba(0,0,0,.54)',
        fontSize: '10px',
      },
    },
    {
      selector: 'icon',
      sortable: false,
      width: '60px',
      cell: d => <img
        className="my-2"
        height="40"
        width="32"
        alt={places.title} src={`https://pinavo.cz/assets/pin-icon/${d.icon}.svg`}
      />,
    },
    {
      name: 'Name',
      selector: 'title',
      sortable: true,
      width: '170px',
      style: {
        color: '#202124',
        fontSize: '14px',
        fontWeight: 500,
      },
    },
    {
      name: 'Rating',
      width: '90px',
      cell: d =>  {
        return (
          <>
            <img src="https://pinavo.cz/assets/pin-icon/pin-rating-full.svg"/><span>&nbsp;{d.rating.avgRating.toFixed(1)}&nbsp;<span>({d.rating.numRatings}x)</span></span></>
        )
      },
    },
    {
      selector: row => <> {
        (() => {
          if (row.active === '0')
            return <Badge pill bg="light" text="dark" row={row}>inactive</Badge>
          else if (row.active === '1')
            return <Badge pill bg="primary" row={row}>active</Badge>
          else if (row.active === '2')
            return <Badge pill bg="dark" row={row}>unknown state</Badge>
        })()
      } </>,
      sortable: false,
      width: '115px',
      grow: 0,
    },
    {
      selector: row => row.verified ? <Badge pill bg="success" row={row}>verified</Badge> : <Badge pill bg="danger" row={row}>not verified</Badge>,
      sortable: false,
      grow: 0,
    },
    {
      name: 'Contributor',
      selector: 'name',
      sortable: true,
      width: '150px',
    },
    {
      name: 'Created',
      selector: 'created',
      sortable: true,
      grow: 0,
      style: {
        color: 'rgba(0,0,0,.54)',
      },
    },
    {
      name: 'Updated',
      selector: 'lastMod',
      sortable: true,
      grow: 0,
      style: {
        color: 'rgba(0,0,0,.54)',
      },
    },
    {
      name: 'Country',
      selector: 'country_code',
      sortable: true,
      grow: 0,
      width: '85px',
      center: true,
      style: {
        color: 'rgba(0,0,0,.54)',
      },
    },
    {
      right: true,
      selector: row =>   <>
        <span className="pointer" title="show on map" onClick={() => window.location.href = `/map?lat=${row.lat}&lng=${row.lng}&z=17`}>
          <FontAwesomeIcon icon={faMapMarkedAlt} style={{color: '#0b182a'}} size='lg' />
        </span>&nbsp;&nbsp;&nbsp;
        <CopyToClipboard text={`${row.lat} ${row.lng}`}>
          <span className="pointer" title="copy coordinates" onClick={() => setToastCopied(true, setToastCopiedTitle(row.title))}>
            <FontAwesomeIcon icon={faCopy} style={{color: '#3771c8'}} size='lg' />
          </span>
        </CopyToClipboard>&nbsp;&nbsp;&nbsp;
        <span className="pointer" title="creare a report" onClick={() => (setReport(true), setPlaceID(`${row.pid}`))}>
          <FontAwesomeIcon icon={faBug} style={{color: 'orange'}} size='lg' />
        </span>
      </>,
    },
  ]

  useEffect(() => {

    placesRef.onSnapshot((querySnapshot) => {
      const items = []
      querySnapshot.forEach((doc) => {
        items.push({
          data: doc.data(),
          rating: doc.data().rating,
          uid: doc.data().contributor.uid,
          pid: doc.data().properties.pid,
          name: doc.data().contributor.name,
          title: doc.data().properties.title,
          verified: doc.data().properties.verified,
          activation: doc.data().properties.activationType,
          nonstop: doc.data().properties.nonstop,
          active: doc.data().properties.active,
          created: doc.data().properties.added.toDate().toLocaleDateString(),
          lastMod: doc.data().properties.lastModified.toDate().toLocaleDateString(),
          lat: doc.data().geometry.coordinates.latitude,
          lng: doc.data().geometry.coordinates.longitude,
          icon: doc.data().properties.type,
          country_code: doc.data().address.country_code,
          id: doc.id
        })
      })
      setPlaces(items)
    })

    const timeout = setTimeout(() => {
      setPending(false)
    }, 2000)
    return () => clearTimeout(timeout)

  }, [])

  return (
    isAllowed &&
    <Layout>
      <div>
        <div className="main-container">
          <header className="text-white">
            <div className="d-flex justify-content-center sm-adjust">
              <h1>List of places</h1>
            </div>
          </header>
          <Container className="py-4" fluid>
            <DataTableExtensions
              columns={columns}
              data={places}
              filterPlaceholder="search places"
              print={false}
              export={false}
              exportHeaders={true}
            >
              <DataTable
                columns={columns}
                data={places}
                defaultSortField="pid"
                defaultSortAsc={false}
                progressPending={pending}
                progressComponent={<LinearIndeterminate />}
                pagination
                customStyles={customStyles}
                highlightOnHover
                noHeader
              />
            </DataTableExtensions>
            <ReportPlaceModal
              profile={profile}
              report={report}
              setReport={setReport}
              handleCloseReport={handleCloseReport}
              placeID={placeID}
            />
            <Toast
              onClose={() => setToastCopied(false)}
              show={toastCopied}
              delay={3000}
              autohide
            >
              <Toast.Header>
                <strong className="me-auto">Pinavo</strong>
                <small>info</small>
              </Toast.Header>
              <Toast.Body>GPS coordinates of <b>{toastCopiedTitle}</b> was copied!</Toast.Body>
            </Toast>
          </Container>
          <Footer />
        </div>
      </div>
    </Layout>
  )

}
